<template>
  <div style="background: #eaeaea; height: 100%">
    <div>
      <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/lunbo1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img
            src="../../assets/F2CE8085-E4E7-4214-8473-6D61924D1A0D.png"
            alt=""
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            src="../../assets/31F3B990-F1D3-415c-8AAD-E85731C33CA1.png"
            alt=""
          />
        </van-swipe-item>
      </van-swipe> -->
    </div>
    <div class="my-list">
      <div class="my-lists"
        :border="false"
        :column-num="3">
        <!--  -->
        <div class="lists">
          <router-link to="/register/newAdd">
            <img src="../../assets/yeguang.png"
              alt="" />
            <span>居民信息登记</span>
          </router-link>
        </div>
        <div class="lists">
          <router-link to="/register/yimiao">
            <img src="../../assets/xuqiu.png"
              alt="" />
            <span>疫苗接种登记</span>
          </router-link>
        </div>
        <div class="lists">
          <router-link to="/register/luo">
            <img src="../../assets/ziyuan.png"
              alt="" />
            <span>返洛人员登记</span>
          </router-link>
        </div>
      </div>
    </div>
    <router-link to="/">
      <div class="nameResume">返回首页</div>
    </router-link>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  methods: {
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style>
.my-swipe .van-swipe-item {
  color: #fff;
  text-align: center;
}
.my-swipe img {
  width: 100%;
}
.my-list {
  font-size: 14px;
}
.my-lists .lists {
  position: relative;
  width: 90%;
  margin: 25px 5%;
  background: #fff;
  border-radius: 15px;
}
.my-lists span {
  position: absolute;
  top: 30px;
  left: 130px;
  font-size: 18px;
  color: #000;
}
.my-lists img {
  width: 20%;
  padding: 10px;
}
.jianjie {
  padding-bottom: 20px;
  border-top: 10px solid #f8f5f5;
  border-bottom: 10px solid #f8f5f5;
}
.jianjie div {
  display: inline-block;
}
.jianjieA {
  position: relative;
  margin-left: 5%;
}
.jianjieA img {
  width: 75%;
}
.makebox div {
  /* display: flex;
  align-items: center; */
  display: inline-block;
  position: relative;
  width: 45%;
  height: 40px;
  padding: 10px 8px;
  line-height: 40px;
  color: #333;
  border-top: 1px solid #f4f3f3;
  /* border: 1px solid #000; */
}
.makebox span {
  width: 80%;
  font-size: 14px;
  float: left;
}
.makebox img {
  position: absolute;
  top: 10px;
  float: right;
  width: 20%;
}
.nameResume {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #0188fb;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
}
</style>